import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';

import Header from '../components/header';
import Footer from '../components/footer';
import Content from '../components/content';
import Icon from '../components/icon';
import SEO from '../components/seo';

import { sendEmail } from '../api/server';

import cooperateStyles from '../styles/cooperate.module.less';

export default () => {
    const [showJoinUsModal, setShowJoinUsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [joinUsLoading, setJoinUsLoading] = useState(false);

    const [showSupportWay, setShowSupportWay] = useState([
        { title: '产品深度集成', isPositive: true },
        { title: '全面的产品体系', isPositive: true },
        { title: '全面扶持', isPositive: true }
    ]);

    const supportWayList = [
        {
            logo: 'iconjichengkaifa',
            title: '产品深度集成',
            content:
                '与简库存产品深度集成，可针对在线上线下、业财税一体化等方面的不同痛点。提供切实有效的针对性解决方案',
            isPositive: true
        },
        {
            logo: 'iconhuabanfuben',
            title: '全面的产品体系',
            content: '从前端到后端，从直销到分销，从PC到移动，从线上到线下，立体化产品体系',
            isPositive: true
        },
        {
            logo: 'iconxiangfuchi',
            title: '全面的扶持',
            content: '全面从产品技术、服务、市场、多维助推，满足合作商放入市场需求',
            isPositive: true
        }
    ];

    const supportPolicyList = [
        {
            logo: 'iconpinpai',
            title: '品牌支持',
            content: '允许渠道伙伴在线上推广以及线下市场活动中使用店货通品牌'
        },
        {
            logo: 'iconkehuguanli',
            title: '管理支持',
            content: '提供基于市场营销，实施服务，团队建设，运营管理等各方面的标准化支持辅导'
        },
        {
            logo: 'iconpeixun',
            title: '培训支持',
            content: '专属合作伙伴拓展经理提供业务支持和商务支持，帮助您在云计算领域取得成功'
        },
        {
            logo: 'iconshichangyingxiao',
            title: '市场和营销支持',
            content: '客户沙龙、研讨会，行业展 会，用户大会等丰富的市场和营销机会'
        },
        {
            logo: 'iconjishufuwu',
            title: '技术和服务支持',
            content: '专项技术领域获得店货通专业架 构师团队的技术支持，在垂直市场获得领先优势'
        }
    ];

    const applyFlowList = [
        {
            logo: 'icontianxie',
            title: '填写申请表'
        },
        {
            logo: 'iconicon-',
            title: '对接经理'
        },
        {
            logo: 'iconshidikaocha',
            title: '实地考察'
        },
        {
            logo: 'iconhezuo',
            title: '确认合作'
        },
        {
            logo: 'iconbusiness-management',
            title: '开展业务'
        }
    ];

    const data = useStaticQuery(graphql`
        query {
            bannerImage: file(relativePath: { eq: "bg/cooperate_bg.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 557) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            policyImage: file(relativePath: { eq: "bg/policy_bg.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 580) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    // 处理卡片切换效果
    const handleMouseEnter = (e, index) => {
        setShowSupportWay((supportWay) => {
            let supportWayArray = [...supportWay];
            let isPositive = supportWayArray[index].isPositive;
            const title = supportWayArray[index].title;
            supportWayArray.splice(index, 1, { title, isPositive: !isPositive });
            return supportWayArray;
        });
    };

    const handleMouseLeave = (e, index) => {
        setShowSupportWay((supportWay) => {
            let supportWayArray = [...supportWay];
            let isPositive = supportWayArray[index].isPositive;
            const title = supportWayArray[index].title;
            supportWayArray.splice(index, 1, { title, isPositive: !isPositive });
            return supportWayArray;
        });
    };

    // 处理表格提交
    const handleApply = async (values) => {
        // console.log(values);
        setLoading(true);
        const queryParams = {
            fullName: values.userName,
            mobile: values.userPhone,
            clientName: values.companyName,
            to: 'wangyang@guojing.net',
            productName: '店货通',
            email: values.userEmail,
            size: values.companyType,
            content: values.userMessage,
            position: ''
        };
        const res = await sendEmail(queryParams);
        if (res) {
            message.info('提交成功');
        }
        setLoading(false);
    };

    // 处理申请成为合作伙伴
    const handlePartnerApply = () => {
        setShowJoinUsModal(true);
    };

    const handleJoinUs = async (values) => {
        // console.log(values);
        setJoinUsLoading(true);
        const queryParams = {
            fullName: values.userName,
            mobile: values.userPhone,
            clientName: values.companyName,
            to: 'wangyang@guojing.net',
            productName: '店货通',
            email: values.userEmail,
            size: values.companyScale,
            content: values.userMessage,
            position: values.userPosition
        };
        const res = await sendEmail(queryParams);
        if (res) {
            message.info('提交成功');
        }
        setJoinUsLoading(false);
    };

    return (
        <div>
            <SEO title="渠道合作" />
            <Header />
            <div className={cooperateStyles.cooperateHeader}>
                <Img fluid={data.bannerImage.childImageSharp.fluid} />
                <div className={cooperateStyles.cooperateHeader__content}>
                    <h2>店货通合作</h2>
                    <p>店货通以推动电商圈卖家提高自身经营水平、抱团发展为目的，</p>
                    <p>让电商圈里的卖家共同发展、共同进步。</p>
                    <p>诚邀您的加入，让我们一起报团取暖、共同成长！</p>
                </div>
            </div>

            <Content
                className={cooperateStyles.supportWay}
                title="我们为渠道商提供的扶持"
                titleStyle={{ fontSize: '30px' }}
                descriptionStyle={{ fontSize: '20px' }}
            >
                <ul className={cooperateStyles.supportWayGroup}>
                    {supportWayList.map((supportWay, index) => {
                        if (showSupportWay[index].isPositive) {
                            return (
                                <li
                                    role="presentation"
                                    className={cooperateStyles.card}
                                    key={index}
                                    onMouseEnter={(e) => handleMouseEnter(e, index)}
                                    onMouseLeave={(e) => handleMouseLeave(e, index)}
                                >
                                    <section className={cooperateStyles.card_positive}>
                                        <Icon
                                            type={supportWay.logo}
                                            className={cooperateStyles.card_positive__logo}
                                        />
                                        <p className={cooperateStyles.card_positive__title}>
                                            {supportWay.title}
                                        </p>
                                    </section>
                                </li>
                            );
                        } else {
                            return (
                                <li
                                    role="presentation"
                                    className={cooperateStyles.card}
                                    key={index}
                                    onMouseEnter={(e) => handleMouseEnter(e, index)}
                                    onMouseLeave={(e) => handleMouseLeave(e, index)}
                                >
                                    <section className={`${cooperateStyles.card_negative}`}>
                                        <p className={cooperateStyles.card_negative__title}>
                                            {supportWay.title}
                                        </p>
                                        <p className={cooperateStyles.card_negative__content}>
                                            {supportWay.content}
                                        </p>
                                    </section>
                                </li>
                            );
                        }
                    })}
                </ul>
            </Content>
            <div className={cooperateStyles.supportPolicy}>
                <Img fluid={data.policyImage.childImageSharp.fluid} />
                <div className={cooperateStyles.supportPolicy__content}>
                    <Content
                        title="五大扶持政策， 全面提升合作伙伴盈利能力"
                        titleStyle={{ fontSize: '30px' }}
                        descriptionStyle={{ fontSize: '20px' }}
                    >
                        <ul className={cooperateStyles.supportPolicy__group}>
                            {supportPolicyList.map((supportPolicy, index) => (
                                <li className={cooperateStyles.policy} key={index}>
                                    <Icon
                                        type={supportPolicy.logo}
                                        className={cooperateStyles.policy__logo}
                                    />
                                    <p className={cooperateStyles.policy__title}>
                                        {supportPolicy.title}
                                    </p>
                                    <p className={cooperateStyles.policy__content}>
                                        {supportPolicy.content}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </Content>
                </div>
            </div>
            <div className={cooperateStyles.applyCooperate}>
                <Content
                    title="欢迎成为我们的合作伙伴"
                    titleStyle={{ fontSize: '30px' }}
                    descriptionStyle={{ fontSize: '20px' }}
                >
                    <Form
                        className={cooperateStyles.applyCooperate__form}
                        onFinish={handleApply}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ offset: 1, span: 12 }}
                        size="large"
                    >
                        <Form.Item
                            label="联系人"
                            name="userName"
                            rules={[{ required: true, message: '请填写您的姓名' }]}
                        >
                            <Input placeholder="请填写您的姓名" />
                        </Form.Item>
                        <Form.Item
                            label="联系电话"
                            name="userPhone"
                            rules={[
                                { required: true, message: '请填写您的电话' },
                                { pattern: /^1(3|4|5|6|7|8)\d{9}$/, message: '请填写合法的手机号' }
                            ]}
                        >
                            <Input placeholder="请填写您的联系电话" />
                        </Form.Item>
                        <Form.Item label="公司名称" name="companyName">
                            <Input placeholder="请填写您的公司名称" />
                        </Form.Item>
                        <Form.Item label="公司类型" name="companyType">
                            <Input placeholder="请填写您的公司类型" />
                        </Form.Item>
                        <Form.Item label="联系邮箱" name="userEmail">
                            <Input placeholder="请填写您的联系邮箱" />
                        </Form.Item>
                        <Form.Item label="您的留言" name="userMessage">
                            <Input.TextArea placeholder="留言给我们" rows={4}></Input.TextArea>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                type="primary"
                                className={cooperateStyles.applyCooperate__form__item__button}
                                loading={loading}
                            >
                                提交申请
                            </Button>
                        </Form.Item>
                    </Form>
                </Content>
            </div>
            <div className={cooperateStyles.applyFlow}>
                <Content>
                    <h2 className={cooperateStyles.applyFlow__title}>合作申请流程</h2>
                    <ul className={cooperateStyles.applyFlow__stepGroup}>
                        {applyFlowList.map((val, index) => (
                            <li className={cooperateStyles.applyFlow__step} key={index}>
                                <Icon
                                    type={val.logo}
                                    className={cooperateStyles.applyFlow__step__logo}
                                />
                                <p className={cooperateStyles.applyFlow__step__title}>
                                    {val.title}
                                </p>
                            </li>
                        ))}
                    </ul>
                    <Button
                        className={cooperateStyles.applyFlow__step__button}
                        onClick={handlePartnerApply}
                    >
                        我要成为店货通合作伙伴
                    </Button>
                </Content>
            </div>

            <Modal
                visible={showJoinUsModal}
                title="加入我们"
                footer={null}
                maskClosable={false}
                onCancel={() => setShowJoinUsModal(false)}
                className={cooperateStyles.modalJoin}
            >
                <Form className={cooperateStyles.modalJoinContent} onFinish={handleJoinUs}>
                    <Form.Item
                        name="userName"
                        rules={[{ required: true, message: '请填写您的姓名' }]}
                    >
                        <Input placeholder="请输入您的姓名*" />
                    </Form.Item>
                    <Form.Item
                        name="userPhone"
                        rules={[
                            { required: true, message: '请填写您的电话' },
                            { pattern: /^1(3|4|5|6|7|8)\d{9}$/, message: '请填写合法的手机号' }
                        ]}
                    >
                        <Input placeholder="请输入您的手机号" />
                    </Form.Item>
                    <Form.Item name="companyName">
                        <Input placeholder="请输入您的公司名称" />
                    </Form.Item>
                    <Form.Item name="userPosition">
                        <Input placeholder="请输入您所担任的职位" />
                    </Form.Item>
                    <Form.Item name="companyScale">
                        <Input placeholder="请输入您的公司规模" />
                    </Form.Item>
                    <Form.Item name="userEmail">
                        <Input placeholder="请输入您的邮箱" />
                    </Form.Item>
                    <Form.Item name="userMessage">
                        <Input.TextArea rows={4} placeholder="留言给我们" />
                    </Form.Item>
                    <Button
                        className={cooperateStyles.modalJoinContentBtn}
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={joinUsLoading}
                    >
                        提交申请
                    </Button>
                </Form>
            </Modal>
            <Footer />
        </div>
    );
};
